import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/client';
import { GeistProvider, CssBaseline, Themes } from '@geist-ui/core';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import './index';

const forgeDark = Themes.createFromDark({
  type: 'darkF',
  palette: {
    background: '#171717',
    success: '#404040',
    successLight: '#595959',
    successDark: '#0c0c0c',
    link: '#e14a34'
  },
  
})

ReactDOM.createRoot(document.getElementById("root")!).render(

  <React.StrictMode>
    <ApolloProvider client={client}>
      <GeistProvider themes={[forgeDark]} themeType='darkF'>
        <CssBaseline />
        <BrowserRouter>
        <App />
        </BrowserRouter>
      </GeistProvider>
    </ApolloProvider>
  </React.StrictMode>
);
